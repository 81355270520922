import "./scss/style.scss";
import "bootstrap";
import "url-polyfill";
import { WOW } from "wowjs";
import { Swiper, Pagination } from "swiper/js/swiper.esm.js";
// import { ajaxForm } from "../node_modules/jquery-form/dist/jquery.form.min.js";
import "../node_modules/jquery-form/dist/jquery.form.min.js";
import "../src/puzzle.js";
$("header").load("/views/header.html");
$("footer").load("/views/footer.html");

function startTimer(duration, display) {
	var timer = duration,
		minutes,
		seconds;
	setInterval(function () {
		minutes = parseInt(timer / 60, 10);
		seconds = parseInt(timer % 60, 10);

		minutes = minutes < 10 ? "0" + minutes : minutes;
		seconds = seconds < 10 ? "0" + seconds : seconds;

		display.textContent = minutes + ":" + seconds;

		if (--timer < 0) {
			timer = duration;
		}
	}, 1000);
}

if ($.contains(document.body, document.getElementById("slider-main"))) {
	Swiper.use([Pagination]);

	var mySwiper = new Swiper(".swiper-container", {
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		loop: true,
	});
}

var wow = new WOW({
	live: false,
	scrollContainer: null,
});

wow.init();

$("#block-modal-terms-of-service").modal("show");

//dataLayer = [];
// if (SITES_SERVER == 0) {
// 	var stripe = Stripe("pk_live_O43WGIxoOxpLqKcwK3O5Uk8300NCepvMi5");
// } else {
// 	var stripe = Stripe("pk_test_ZiJ24Th1n2qxfSBCYENn2L6x00xrcvHi5I");
// }
$(document).ready(function () {
	if ($("#paycometPaymentForm").length > 0) {
		createFormPayment();
	}
	if ($("#btn-cancelar-cuenta").length > 0) {
		var form = document.getElementById("form-baja");

		form.addEventListener("submit", function (event) {
			event.preventDefault();

			$.ajax({
				cache: false,
				type: "POST",
				url: "/sabadell.php",
				data: $("#form-baja").serialize() + "&action=cancel",
			})
				.done(function (data) {
					var content = JSON.parse(data);

					if (content.status) {
						$("#form-baja .alert-success").html(content.info);
						$("#form-baja .alert-success").fadeIn();
					} else {
						$("#form-baja .alert-danger").html(content.info);
						$("#form-baja .alert-danger").fadeIn();
					}
				})
				.fail(function () {
					alert(
						"¡No te hemos podido dar de baja! Revisa tus datos e inténtalo de nuevo."
					);
				})
				.always(function () {
					setTimeout(function () {
						$("#form-baja .alert").hide();
					}, 10000);
				});
		});
	}
});

/*RECUPERAR CONTRASEÑA*/
$("#password_forgotten").on("submit", function (event) {
	event.preventDefault();

	$.ajax({
		cache: false,
		type: "POST",
		url: "/password_forgotten.php",
		data: $(this).serialize(),
	})
		.done(function (data) {
			var content = JSON.parse(data);

			if (content.status) {
				$("#password_forgotten .alert-success").html(content.info);
				$("#password_forgotten .alert-success").removeClass("hidden");
				$("#password_forgotten .alert-success").fadeIn();

				setTimeout(function () {
					//								$(location).attr('href', '/usuarios/enviar-mensaje/');
					$(location).attr("href", "/login/");
				}, 4000);
			} else {
				$("#password_forgotten .alert-danger").html(content.info);

				if (typeof content.extrainfo !== "undefined") {
					$("#password_forgotten  .alert-danger").html(
						content.info + " --- " + content.extrainfo
					);
				}
				$("#password_forgotten .alert-danger").removeClass("hidden");
				$("#password_forgotten .alert-danger").fadeIn();
			}
		})
		.fail(function () {
			alert("¡Ha ocurrido un error, inténtelo de nuevo!");
		})
		.always(function () {
			setTimeout(function () {
				$("#password_forgotten .alert").addClass("hidden");
				$("#password_forgotten .alert").hide();
			}, 10000);
		});
});

if ($.contains(document.body, document.getElementById("accountant-time"))) {
	var accountant = document.querySelector("#accountant-time");
	var accountantMinutes = 60 * accountant.getAttribute("time");
	startTimer(accountantMinutes, accountant);
}

function createFormPayment() {
	var form = document.getElementById("paycometPaymentForm");

	form.addEventListener("submit", function (event) {
		event.preventDefault();
		
		setTimeout(function () {
			// if ()
			//console.log($("#paycometPaymentForm").serialize());
$("#paymentErrorMsg").html("Procesando tu pago. Espera por favor...");
			if (
				typeof $("#paycometPaymentForm")
					.find('input[name="paytpvToken"]')
					.val() !== "undefined"
			) {
				$.ajax({
					cache: false,
					type: "POST",
					url: "/sabadell.php",
					data: $("#paycometPaymentForm").serialize() + "&action=payment",
				})
					.done(function (data) {
						$("#paycometPaymentForm .alert").hide();

						var content = JSON.parse(data);

						if (content.status) {
							$("#paycometPaymentForm")[0].reset();

							$("#paycometPaymentForm .alert-success").html(content.info);

							$("#paycometPaymentForm .alert-success").fadeIn();
							// console.log("enviamos evento de pago");
							// dataLayer.push({
							// 	event: "paymentSuccess",
							// 	eventCategory: "AJAX",
							// });

							 console.log("evento enviado");
							 gtag("event", "conversion", {
									send_to: "AW-605071113/GMakCIu05NoBEInOwqAC",
							 });

							setTimeout(function () {
								$(location).attr("href", "/back/index.php?entity=10");
							}, 3000);
						} else {
							$("#paymentErrorMsg").html(content.info);
							if (typeof content.extrainfo !== "undefined") {
								$("#paymentErrorMsg").html(
									content.info + " --- " + content.extrainfo
								);
							}

							//$("#paymentErrorMsg").fadeIn();
						}
					})
					.fail(function () {
						alert("¡Pago fallido!");
						$("#Ds_Merchant_PayButton").attr("disabled", false);
						$("#paymentErrorMsg").html("");
					})
					.always(function () {
						setTimeout(function () {
							$("#paycometPaymentForm .alert").hide();
						}, 10000);

						$("#Ds_Merchant_PayButton").attr("disabled", false);
					});
			} else {
				$("#paymentErrorMsg").html(
					$("#paymentErrorMsg").text() +
						" --> Error al procesar el pago. Inténtalo de nuevo."
				);
			}
		}, 3000);
	});
}
